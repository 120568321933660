@use '~@angular/material' as mat;

@include mat.core();
@import "app/styles/mixins";
@import "app/styles/colors";
@import "app/styles/material-customized";
@import "app/styles/bg-image";
@import "app/styles/buttons";
@import "app/styles/fonts";
@import "app/styles/responsive";

:root {
  --max-width: 1140px;
  --min-width: 849px;
  --mobile-width: 90%;
}

// fonts
$font-mulish: "Mulish", "sans-serif";

// colors
$brand-color: var(--brand-color);
$bg-dark-color: var(--bg-dark-color);
$site-color: var(--site-color);
$light-link-color: var(--light-link-color);

// sizes
$max-width: var(--max-width);
$min-width: var(--min-width);
$mobile-width: var(--mobile-width);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-mulish !important;
}

body {
  opacity: 0;
  visibility: hidden;  
}

body.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

a {
  text-decoration: none;
  color: $bg-dark-color;
}

h2 {
  color: $brand-color;
}

h3 {
  color: $bg-dark-color;
  font-weight: 600;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.form-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  @include on-smartphone() {
  }

  @include on-desktop() {
    flex-direction: row;
  }

  .mat-form-field {
    color: $bg-dark-color;
    width: 100%;

    @include on-desktop() {
      width: 49%;
    }

    ::ng-deep {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          background-color: white;
          border-radius: 4px 4px 4px 4px;
        }
        .mat-form-field-underline {
          span {
            display: none !important;
          }
        }
      }
    }
  }
}