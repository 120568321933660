@import "./responsive";
.main-button {
  background-color: var(--brand-color);
  color: white;
  //padding: 18px 51.6px 18px 51.6px;
  font-size: 16px;
  line-height: 56px;
  min-width: 203px;
  height: 56px;
  border: none;
  border-radius: 4px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  display: block;

  // @include on-smartphone() {
  //   display: none;
  // }

  @include on-desktop() {
    font-size: 20px;
    // display: block;
  }

  &--search {
    @include on-smartphone() {
      display: none;
    }
  }

  &--anchor {
    @include on-smartphone() {
      display: block;
      width: 100%;
      border: none;
      border-radius: 0 !important;
      font-size: 20px;
      font-weight: 900;
    }
  }

}
