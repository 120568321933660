@import "./responsive";

.main-button {
  background-color: var(--brand-color);
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  line-height: 56px;
  text-align: center;

  @include on-desktop() {
    font-size: 20px;
  }

  &--search {
    @include on-smartphone() {
      display: none;
    }
  }

  &--anchor {
    @include on-smartphone() {
      display: block;
      border: none;
      border-radius: 0 !important;
      font-size: 20px;
      font-weight: 900;
      margin: 0 -20px;
    }
  }
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
  opacity: 0.5;
}