//@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;600;700;900&display=swap");


@font-face {
    font-family: "Mulish";
    src: url("/assets/fonts/Mulish-ExtraLight.ttf");
    font-weight: 200;
}
@font-face {
    font-family: "Mulish";
    src: url("/assets/fonts/Mulish-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Mulish";
    src: url("/assets/fonts/Mulish-SemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "Mulish";
    src: url("/assets/fonts/Mulish-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Mulish";
    src: url("/assets/fonts/Mulish-Black.ttf");
    font-weight: 900;
}
