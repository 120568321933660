@mixin on-smartphone {
  @media screen and (max-width: 1099px) {
    @content;
  }
}

@mixin on-desktop {
  @media screen and (min-width: 1100px) {
    @content;
  }
}

.page-container {
  box-sizing: border-box;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--container-padding);
  width: 100%;
}

.negative-margins {
  margin: 0 -20px;
}