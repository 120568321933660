@mixin on-smartphone {
  @media screen and (max-width: 1099px) {
    @content;
  }
}

// @mixin on-tablet {
//   @media screen and (min-width: 500px) and (max-width: 1119px) {
//     @content;
//   }
// }

@mixin on-desktop {
  @media screen and (min-width: 1100px) {
    @content;
  }
}
